<div class="d-lg-none d-xl-none">
    <ng-container *ngIf="!isProdEnvironment()">
        <div class="container-fluid bg-warning">
            <div class="row">
                <div class='col-sm-12'>
                    <b>¡NOTA IMPORTANTE!</b> Edificio Sano se está ejecutando sobre un ambiente de pruebas. Cualquier cambio realizado no afectará al ambiente productivo.
                </div>
            </div>
        </div>
    </ng-container>
    <nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="userLoggedIn()">
        <a class="navbar-brand" data-toggle="collapse" data-target="#navbarSupportedContent" [routerLink]="['/dashboard/home']">
            <img src="assets/img/filtech_logo.png" height="40px" alt="">
        </a>
        <button *ngIf="!!sessionHandlerServiceRef.getUserAuthenticated()" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item dropdown" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.USUARIOS_ACCESO_MODULO)">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Usuarios
                    </a>
                    <div class="dropdown-menu shadows" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" [routerLink]="['/cuentas/crear']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.USUARIOS_PERMITIR_CREACION)">Creación</a>
                        <a class="dropdown-item" [routerLink]="['/cuentas/lista']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.USUARIOS_PERMITIR_LISTADO)">Lista</a>
    
                        <!-- <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">Something else here</a> -->
                    </div> 
                </li>        
                <li class="nav-item dropdown" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.TRACKING_ACCESO_MODULO)">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Tracking
                    </a>
                    <div class="dropdown-menu shadows" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" [routerLink]="['/tecnicos']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.TRACKING_TECNICOS_ACCESO_MODULO)">Tecnicos</a>
                        <a class="dropdown-item" [routerLink]="['/servicios']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.TRACKING_SERVICIOS_ACCESO_MODULO)">Servicios</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" [routerLink]="['/checkpoints/servicios']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.TRACKING_CHECKLIST_ACCESO_MODULO)">Checklist</a>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/disenador/servicios']"  *ngIf="sessionHandlerServiceRef.permissionExists(permissions.DISENADOR_ACCESO_MODULO)">
                        Dise&ntilde;ador
                    </a>
                </li> 
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/cotizaciones/presupuesto']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.COTIZACION_ACCESO_MODULO)">
                        Presupuesto
                    </a>
                </li> 
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/post-venta/lista']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.POSTVENTA_ACCESO_MODULO)">
                        Post-venta
                    </a>
                </li>  
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/asistencias/captura']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.ASISTENCIAS_ACCESO_MODULO)">
                        Asistencias
                    </a>
                </li>       
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/bonificaciones/captura']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.BONIFICACIONES_ACCESO_MODULO)">
                        Bonificaciones
                    </a>
                </li>  
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/centro/lista']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.BONIFICACIONES_ACCESO_MODULO)">
                        Encuestas
                    </a>
                </li>       
            </ul>
            <div class="form-inline my-2 my-lg-0" *ngIf="!!sessionHandlerServiceRef.getUserAuthenticated()">
                <button class="btn btn-outline-primary my-2 my-sm-0" (click)="signOut()">Cerrar Sesión</button>
            </div>
        </div>
    </nav>
</div>

<div class="uhf-fullwidth d-none d-lg-block">
    <ng-container *ngIf="!isProdEnvironment()">
        <div class="container-fluid bg-warning">
            <div class="row">
                <div class='col-sm-12'>
                    <b>¡NOTA IMPORTANTE!</b> Edificio Sano se está ejecutando sobre un ambiente de pruebas. Cualquier cambio realizado no afectará al ambiente productivo.
                </div>
            </div>
        </div>
    </ng-container>
    <div class="container-fluid" *ngIf="userLoggedIn()">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12 d-flex justify-content-center">
                    <a data-toggle="collapse" data-target="#navbarSupportedContent" [routerLink]="['/dashboard/home']">
                        <img src="assets/img/filtech_logo.png" height="60px" alt="">
                    </a>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <ul class="d-flex justify-content-center" id="uhf-navbar">
                <li *ngIf="sessionHandlerServiceRef.permissionExists(permissions.USUARIOS_PERMITIR_LISTADO)">
                    <a [routerLink]="['/cuentas/lista']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.USUARIOS_PERMITIR_LISTADO)">Usuarios</a>
                </li>        
                <li *ngIf="sessionHandlerServiceRef.permissionExists(permissions.TRACKING_TECNICOS_ACCESO_MODULO)">
                    <a [routerLink]="['/tecnicos']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.TRACKING_TECNICOS_ACCESO_MODULO)">Tecnicos</a>
                </li>
                <li *ngIf="sessionHandlerServiceRef.permissionExists(permissions.TRACKING_SERVICIOS_ACCESO_MODULO)">        
                    <a [routerLink]="['/servicios']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.TRACKING_SERVICIOS_ACCESO_MODULO)">Servicios</a>
                </li>
                <li *ngIf="sessionHandlerServiceRef.permissionExists(permissions.TRACKING_CHECKLIST_ACCESO_MODULO)">
                    <a [routerLink]="['/checkpoints/servicios']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.TRACKING_CHECKLIST_ACCESO_MODULO)">Checklist</a>
                </li>
                <li *ngIf="sessionHandlerServiceRef.permissionExists(permissions.DISENADOR_ACCESO_MODULO)">
                    <a class="nav-link" [routerLink]="['/disenador/servicios']">
                        Dise&ntilde;ador
                    </a>
                </li> 
                <li>
                    <a class="nav-link" [routerLink]="['/cotizaciones/presupuesto']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.COTIZACION_ACCESO_MODULO)">
                        Presupuesto
                    </a>
                </li> 
                <li>
                    <a class="nav-link" [routerLink]="['/post-venta/lista']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.POSTVENTA_ACCESO_MODULO)">
                        Post-venta
                    </a>
                </li>  
                <li>
                    <a class="nav-link" [routerLink]="['/asistencias/captura']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.ASISTENCIAS_ACCESO_MODULO)">
                        Asistencias
                    </a>
                </li>       
                <li>
                    <a class="nav-link" [routerLink]="['/bonificaciones/captura']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.BONIFICACIONES_ACCESO_MODULO)">
                        Bonificaciones
                    </a>
                </li>
                <li>
                    <a class="nav-link" [routerLink]="['/centro/lista']" *ngIf="sessionHandlerServiceRef.permissionExists(permissions.BONIFICACIONES_ACCESO_MODULO)">
                        Encuestas
                    </a>
                </li>       
                <li>
                    <div class="form-inline my-2 my-lg-0" *ngIf="!!sessionHandlerServiceRef.getUserAuthenticated()">
                        <button class="btn btn-outline-primary my-2 my-sm-0" (click)="signOut()">Cerrar Sesión</button>
                    </div>
                </li>       
            </ul>
        </div>
        <div class="container-fluid">
            &nbsp;
        </div>
    </div>
</div>