import { Routes } from '@angular/router';
import { IsNotAuthenticatedGuard } from './modules/users/guards/is-not-authenticated.guard';
import { IsAuthenticatedGuard } from './modules/users/guards/is-authenticated.guard';
import { AccountsModuleAccessGuard } from './modules/accounts/guards/accounts-module-access.guard';
import { TechniciansModuleAccessGuard } from './modules/technicians/guards/technicians-module-access.guard';
import { SellingServicesModuleAccessGuard } from './modules/selling-services/guards/selling-services-module-access.guard';
import { CheckpointsModuleAccessGuard } from './modules/checkpoints/guards/checkpoints-module-access.guard';
import { MarketQuotesModuleAccessGuard } from './modules/market-quotes/guards/market-quotes-module-access.guard';
import { DesignerModuleAccessGuard } from './modules/designer/guards/designer-module-access.guard';
import { PostSellSchedulesModuleAccessGuard } from './modules/post-sell-schedules/guards/post-sell-schedules-module-access.guard';
import { BonificationsModuleAccessGuard } from './modules/bonifications/guards/bonifications-module-access.guard';
import { PresencesModuleAccessGuard } from './modules/presences/guards/presences-module-access.guard';

export const appMainRoutes: Routes = [
    {
      path: '',
      redirectTo: '/usuarios/login',
      pathMatch: 'full',
    },
    // Accounts and users
    {
      path: 'usuarios',
      canActivate: [ IsNotAuthenticatedGuard ],
      loadChildren: () => import ('./modules/users/users.module').then (m => m.UsersModule)
    },
    {
      path: 'cuentas',
      canActivate: [
        IsAuthenticatedGuard,
        AccountsModuleAccessGuard
      ],
      loadChildren: () => import ('./modules/accounts/accounts.module').then (m => m.AccountsModule)
    },
    {
      path: 'tecnicos',
      canActivate: [
        IsAuthenticatedGuard,
        TechniciansModuleAccessGuard
      ],
      loadChildren: () => import ('./modules/technicians/technicians.module').then (m => m.TechniciansModule)
    },
    // Not visible due projects are retrieved from filtech cache
    // {
    //   path: 'proyectos',
    //   canActivate: [ IsAuthenticatedGuard ], // not 
    //   loadChildren: () => import ('./modules/projects/projects.module').then (m => m.ProjectsModule)
    // },
    {
      path: 'servicios',
      canActivate: [
        IsAuthenticatedGuard,
        SellingServicesModuleAccessGuard
      ],
      loadChildren: () => import ('./modules/selling-services/selling-services.module').then (m => m.SellingServicesModule)
    },
    {
      path: 'checkpoints',
      canActivate: [
        IsAuthenticatedGuard,
        CheckpointsModuleAccessGuard
      ],
      loadChildren: () => import('./modules/checkpoints/checkpoints.module').then (m => m.CheckpointsModule)
    },
    {
      path: 'encuestas',
      loadChildren: () => import ('./modules/polls/polls.module').then (m => m.PollsModule)
    },
    {
      path: 'centro',
      loadChildren: () => import ('./modules/polls/polls.module').then (m => m.PollsModule)
    },
    {
      path: 'cotizaciones',
      canActivate: [
        IsAuthenticatedGuard,
        MarketQuotesModuleAccessGuard
      ],
      loadChildren: () => import ('./modules/market-quotes/market-quotes.module').then (m => m.MarketQuotesModule)
    },
    {
      path: 'disenador',
      canActivate: [
        IsAuthenticatedGuard,
        DesignerModuleAccessGuard
      ],
      loadChildren: () => import ('./modules/designer/designer.module').then (m => m.DesignerModule)
    },
    {
      path: 'post-venta',
      canActivate: [
        IsAuthenticatedGuard,
        PostSellSchedulesModuleAccessGuard
      ],
      loadChildren: () => import ('./modules/post-sell-schedules/post-sell-schedules.module').then (m => m.PostSellSchedulesModule)
    },
    {
      path: 'bonificaciones',
      canActivate: [
        IsAuthenticatedGuard,
        BonificationsModuleAccessGuard
      ],
      loadChildren: () => import ('./modules/bonifications/bonifications.module').then (m => m.BonificationsModule)
    },
    {
      path: 'asistencias',
      canActivate: [
        IsAuthenticatedGuard,
        PresencesModuleAccessGuard
      ],
      loadChildren: () => import ('./modules/presences/presences.module').then (m => m.PresencesModule)
    },
    {
      path: 'planificaciones',
      canActivate: [
        IsAuthenticatedGuard
      ],
      loadChildren: () => import ('./modules/planifications/planifications.module').then (m => m.PlanificationsModule)
    },
    {
      path: 'dashboard',
      canActivate: [ IsAuthenticatedGuard ],
      loadChildren: () => import ('./modules/dashboard/dashboard.module').then (m => m.DashboardModule)
    },
  ];