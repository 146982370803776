import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { UserModel } from 'src/app/modules/accounts/models/user-model';
import { PermissionModel } from 'src/app/modules/accounts/models/permission-model';
import { RoleModel } from 'src/app/modules/accounts/models/role-model';
import { ENUM_PERMISSIONS } from '../enums/permissions';

@Injectable({
  providedIn: 'root'
})
export class SessionHandlerService {
  userSession: UserModel;
  constructor(
    private storageService: StorageService
  ) { }

  public getPermissions(): Array<PermissionModel> {
    return this.storageService.getItem<Array<PermissionModel>>('userPermissions');
  }

  public getRole(): Array<RoleModel> {
    return this.storageService.getItem<Array<RoleModel>>('userRole');
  }

  public getUserAuthenticated(): UserModel {
    return this.storageService.getItem<UserModel>('userAuthenticated');
  }

  public setPermissions(permissions: Array<PermissionModel>): void {
    this.storageService.addItem('userPermissions', permissions);
  }

  public setUserAuthenticated(user: UserModel): void {
    this.storageService.addItem('userAuthenticated', user);
  }

  public setRole(user: RoleModel): void {
    this.storageService.addItem('userRole', user);
  }

  public clearStorage(): void{
    this.storageService.removeItem('userPermissions');
    this.storageService.removeItem('userAuthenticated');
    this.storageService.removeItem('userRole');
  }

  public permissionExists(permission: ENUM_PERMISSIONS): boolean {
    const storedPermissions = this.getPermissions();
    return !!storedPermissions ? storedPermissions.filter((storedPermission: PermissionModel) => {
      return storedPermission.id == permission;
    }).length > 0 : false;
  }
}
