<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-lg-4 text-left">
            <div class="form-group">
                <label for="year">Seleccione el a&ntilde;o</label>
                <select class="form-control" name="year" id="year" (change)="emitOnChange()" [(ngModel)]="weekDatePicker.selectionableYear">
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </select>
            </div>
        </div>
    </div>
</div>