import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public addItem(key: string, object: any){
    let newItem = btoa(JSON.stringify(object));
    localStorage.setItem(key, newItem);
    return newItem;
  }

  public getItem<T>(key: string): T {
    let storedItem = atob(localStorage.getItem(key));
    if(storedItem){
      try{
        return JSON.parse(storedItem) as T;
      } catch (err){
        return null;
      }
    } return null;
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
