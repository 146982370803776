import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FileManagerService } from '../../services/file-manager.service';
import { SingleFileForm } from '../../reactive-forms/single-file-form';
import { FileModel } from '../../models/file-model';

@Component({
  selector: 'file-manager-single-file',
  templateUrl: './single-file.component.html',
  styleUrls: ['./single-file.component.css']
})
export class SingleFileComponent implements OnInit {

  formGroup: FormGroup;
  @Output() onFileUploaded: EventEmitter<FileModel> = new EventEmitter();
  @Output() onFileSelected: EventEmitter<boolean> = new EventEmitter();
  
  constructor(
    private formBuilder: FormBuilder,
    private fileManagerService: FileManagerService
  ) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group(SingleFileForm);
  }

  onFileChange(event){
    if(event.target.files.length > 0){
      this.onFileSelected.emit(true);
      let file = event.target.files[0];
      this.formGroup.patchValue({fileSource: file});
      this.fileManagerService.upload(this.formGroup.get('fileSource').value).subscribe((fileCreated: FileModel) => {
        this.onFileUploaded.emit(fileCreated);
        this.onFileSelected.emit(false);
      });
    }
  }
}
