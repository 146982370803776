import { ApiResponse } from 'src/app/common/interfaces/api-response';
import { FileInterface } from './file-interface';

export class FileModel implements ApiResponse, FileInterface {
    id: number;
    filePath: string;

    constructor(){
        this.filePath = "";
    }
    
    map(data: any): void {
        this.id = data.id;
        this.filePath = data.file_path
    }
    
    parse() {
        return {
            id: this.id,
            file_path: this.filePath
        }
    }

}