import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionHandlerService } from 'src/app/common/services/session-handler.service';
import { RouterHelperService } from 'src/app/common/services/router-helper.service';

@Injectable({
  providedIn: 'root'
})
export class IsNotAuthenticatedGuard implements CanActivate {
  constructor(
    private sessionHandlerService: SessionHandlerService,
    private routerHelperSerivce: RouterHelperService
  ){

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(!this.sessionHandlerService.getUserAuthenticated()){
      return true;
    } else {
      this.routerHelperSerivce.openDashboard();
    }
    return false;
  }
  
}
