import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { appConfig } from 'src/app/config/app-config';
import { APIParamInterface } from '../interfaces/api-param-interface';
import { APIParamModel } from '../models/api-param-model';

@Injectable({
  providedIn: 'root'
})
export class APILinkerService {

  constructor(
    private httpClient: HttpClient
  ) {

  }

  public generateHttpOptions(params: HttpParams, useDefaultHeaders: boolean = true) : any{
    if(useDefaultHeaders){
      return {
        headers: appConfig.global.apiHeaders,
        params: params,
        responseType: appConfig.global.format
      }
    }
    
    return {
      params: params,
      responseType: appConfig.global.format
    }
  }
  
  public getData(uri: string, options: any, params: Array<APIParamModel> = []) {
    if(uri && uri.length > 0){
      let queryParams = '';
      if(!!params){
        queryParams = this.generateQueryParams(params);
      }
      return this.httpClient.get(uri + queryParams, options);
    }
    return null;
  }

  public postData(uri: string, payload: any, options: any){
    if(uri && uri.length > 0)
      return this.httpClient.post(uri, payload, options);
    return null;
  }

  public putData(uri: string, payload: any, options: any){
    if(uri && uri.length > 0)
      return this.httpClient.put(uri, payload, options);
    return null;
  }

  public deleteData(uri: string, options: any){
    if(uri && uri.length > 0)
      return this.httpClient.delete(uri, options);
    return null;
  }

  public generateURI(controller: string = '', resource: string = '', isStandalone: boolean = false): string {
    controller = controller.trim();
    resource = resource.trim();
    if(controller.length > 0){
      if(resource.length > 0){
        return this.generateURL() + `${ controller }/${ resource }.${ appConfig.global.format }`;
      } else {
        return this.generateURL() + `${ controller }.${ appConfig.global.format }`;
      }
    } else {
      return null;
    }
  }

  public generateURL(): string {
    switch(appConfig.environment){
      case 'prod':
        return appConfig.global.baseUrl.prod
      case 'qap':
        return appConfig.global.baseUrl.qap
      case 'qap-remote':
        return appConfig.global.baseUrl.qapRemote
    }
  }

  public generateQueryParams(params: Array<APIParamModel> ) : string{
    if(params.length > 0){
      let queryParams = '?';
      for(let i = 0; i < params.length; i++){
        queryParams += params[i].key + "=" + params[i].value + "&";
      }
      return queryParams;
    }
    return '';
  }
}
