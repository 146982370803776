import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterHelperService {

  constructor(
    private router: Router
  ) { }

  public redirectToLogin(){
    this.router.navigate(['/usuarios/login']);
  }

  public openDashboard(){
    this.router.navigate(['/dashboard']);
  }

  public openAccountsModule(){
    this.router.navigate(['/cuentas']);
  }

  public openTrackingModule(){
    this.router.navigate(['/tracking']);
  }
}
