import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { appMainRoutes } from './app-main-routes';
import { AppComponentsModule } from './common/components/app-components.module';

@NgModule({
  imports: [
    RouterModule.forRoot(appMainRoutes),
    AppComponentsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
