import { Injectable } from '@angular/core';
import { appConfig } from 'src/app/config/app-config';
import { HttpParams, HttpClient } from '@angular/common/http';
import { APILinkerService } from 'src/app/common/services/apilinker.service';
import { Observable } from 'rxjs';
import { FileModel } from '../models/file-model';

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {
  private parseFileToImageObservable: Observable<string>;
  private parseFileToImageObservableStatus: boolean;
  private currentQueue: number;

  constructor(
    private apiLinkerService: APILinkerService,
    private httpClient: HttpClient
  ) { 
    this.currentQueue = 0;
  }

  upload(file: any): Observable<FileModel>{
    return new Observable((observer) => {
      if(this.currentQueue > 3) {
        observer.error('TOO_MANY_UPLOADS_IN_QUEUE');
      } else {
        this.currentQueue++;
        let formData = new FormData();
        formData.append('file', file);

        let uri = this.apiLinkerService.generateURI(appConfig.endpoints.files);
        let options = this.apiLinkerService.generateHttpOptions(new HttpParams(), false);
        // options.reportProgress = true;
        // options.observe = 'events';
        
        this.apiLinkerService.postData(uri,formData,options).subscribe((freshData: any) => {
          if(!!freshData && !!freshData.response){
            const freshResponse = freshData.response;
            if(freshResponse.message === "SAVED_SUCCESSFULLY"){
              let mappedFile = new FileModel();
              this.currentQueue--;
              mappedFile.map(freshResponse.file);
              observer.next(mappedFile);
              observer.complete();
            } else {
              observer.error(freshData);
            }
          } else {
            observer.error(freshData);
          }
        }, (err) => {
          observer.error(err);
        });
      }
    });
  }

  buildImageURL(internalRoute: string): string {
    switch(appConfig.environment){
      case 'qap':
        return appConfig.global.standaloneScripts.downloadImage.qap + "?file=" + internalRoute
      case 'qap-remote':
        return appConfig.global.standaloneScripts.downloadImage.qapRemote + "?file=" + internalRoute
      case 'prod':
        return appConfig.global.standaloneScripts.downloadImage.prod + "?file=" + internalRoute
    }
    return "";
  }

  // Para descargar en privado, aun en pruebas
  parseFileToImage(file: FileModel): Observable<string> {
    if(!this.parseFileToImageObservableStatus) {
      this.parseFileToImageObservable = new Observable<string>((observer) => {
        this.parseFileToImageObservableStatus = true;
        const httpOptions = this.apiLinkerService.generateHttpOptions(new HttpParams());
        const uri = this.apiLinkerService.generateURI(appConfig.endpoints.files, 'parse-to-image');
        const payload = {
          file_id: file.id
        };
        
        const callback = (freshData) => {
          if(!!freshData && !!freshData.response){
            const freshBase64File = freshData.response.encoded_file;
            this.parseFileToImageObservableStatus = false;
            observer.next(freshBase64File);
            observer.complete();
          } else {
            this.parseFileToImageObservableStatus = false;
            observer.error(freshData);
          }
        }

        this.apiLinkerService.postData(uri,payload,httpOptions).subscribe(
          callback,
          (err) => {
            this.parseFileToImageObservableStatus = false;
            observer.error(err);
          }
        )
      });
    }
    return this.parseFileToImageObservable;
  }
}
