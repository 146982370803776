import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterLink, RouterModule } from '@angular/router';
import { ModuleTitleComponent } from './module-title/module-title.component';
import { CustomProgressBarComponent } from './custom-progress-bar/custom-progress-bar.component';
import { WeekDatePickerComponent } from './week-date-picker/week-date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    NavbarComponent,
    ModuleTitleComponent,
    CustomProgressBarComponent,
    WeekDatePickerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    NavbarComponent,
    ModuleTitleComponent,
    CustomProgressBarComponent,
    WeekDatePickerComponent
  ]
})
export class AppComponentsModule { }
