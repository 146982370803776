export enum ENUM_PERMISSIONS {
    USUARIOS_ACCESO_MODULO          = 1,
    USUARIOS_PERMITIR_LISTADO       = 2,
    USUARIOS_PERMITIR_BLOQUEO       = 3,
    USUARIOS_PERMITIR_CREACION      = 4,
    
    DISENADOR_ACCESO_MODULO         = 5,
    DISENADOR_PERMITIR_LISTADO      = 6, 
    DISENADOR_CARGAR_IMAGENES       = 7,
    DISENADOR_GENERAR_PPTX          = 8,
    
    TRACKING_ACCESO_MODULO              = 9,
    TRACKING_PROYECTOS_ACCESO_MODULO    = 10,
    TRACKING_PROYECTOS_LISTADO          = 11,
    TRACKING_PROYECTOS_CREAR            = 12,
    TRACKING_PROYECTOS_ELIMINAR         = 13,

    TRACKING_TECNICOS_ACCESO_MODULO     = 14,
    TRACKING_TECNICOS_LISTADO           = 15,
    TRACKING_TECNICOS_CREAR             = 16,
    TRACKING_TECNICOS_ELIMINAR          = 17,

    TRACKING_SERVICIOS_ACCESO_MODULO                = 18,
    TRACKING_SERVICIOS_LISTADO                      = 19,
    TRACKING_SERVICIOS_CREAR                        = 20,
    TRACKING_SERVICIOS_ELIMINAR                     = 21,
    TRACKING_SERVICIOS_MOSTRAR_PRECIOS_PARTIDAS     = 22,
    TRACKING_SERVICIOS_OCULTAR_OV                   = 41,

    TRACKING_CHECKLIST_ACCESO_MODULO        = 23,
    TRACKING_CHECKLIST_HACER_CHECK          = 24,
    TRACKING_CHECKLIST_CONTESTAR_ENCUESTA   = 25,
    TRACKING_CHECKLIST_ENVIAR_ENCUESTA      = 26,

    COTIZACION_ACCESO_MODULO        = 27,
    COTIZACION_GENERAR_PDF          = 28,

    POSTVENTA_ACCESO_MODULO         = 29,
    POSTVENTA_PDF_CONSULTA          = 30,
    POSTVENTA_PDF_MODIFICACION      = 31,

    ASISTENCIAS_ACCESO_MODULO           = 32,
    ASISTENCIAS_CREAR_NUEVO_PERIODO     = 33,
    ASISTENCIAS_MODIFICAR_ASISTENCIA    = 34,
    
    BONIFICACIONES_ACCESO_MODULO                    = 35,
    BONIFICACION_DESCARGAR_PDF_CUADRILLA            = 36,
    BONIFICACION_DESCARGAR_PDF_TECNICO              = 37,
    BONIFICACION_DETALLE_DE_ASISTENCIA_TECNICO      = 38,
    BONIFICACION_MODIFICAR_BONIFICACION             = 39,
    BONIFICACION_CONFIGURAR_VALORES                 = 40,    
}