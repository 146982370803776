import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// For animations import:
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { FileManagerModule } from './modules/file-manager/file-manager.module';
import { AppComponentsModule } from './common/components/app-components.module';
import { AppServicesModule } from './common/services/app-services.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ timeOut: 3500, enableHtml: true, positionClass: 'toast-bottom-right',}),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    AppRoutingModule,
    AppServicesModule,
    AppComponentsModule,
    FontAwesomeModule,
    FileManagerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
