import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionHandlerService } from 'src/app/common/services/session-handler.service';
import { RouterHelperService } from 'src/app/common/services/router-helper.service';

@Injectable({
  providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionHandlerService: SessionHandlerService,
    private routerHelperSerice: RouterHelperService
  ){ }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(!!this.sessionHandlerService.getUserAuthenticated()){
        return true;
      }
      this.routerHelperSerice.redirectToLogin();
      return false;
  }
  
}
