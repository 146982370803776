export const appConfig = {
    environment: 'prod',
    global: {
        baseUrl: {
            qap: 'http://localhost:8765/',
            qapRemote: 'https://qa.api.edificiosano.com/',
            prod: 'https://prod.api.edificiosano.com/',
        },
        standaloneScripts: {
            downloadImage: {
                qap: 'http://192.168.50.142/safe-building-files/safe-building-standalone/download-image.php',
                qapRemote: 'https://qa.resources.edificiosano.com/standalone-scripts/download-image.php',
                prod: 'https://prod.resources.edificiosano.com/standalone-scripts/download-image.php',
            },
            sapOrders: {
                qap: 'https://sap.mw.edificiosano.com/selling-services/{{sapOrder}}',
                qapRemote: 'https://sap.mw.edificiosano.com/selling-services/{{sapOrder}}',
                prod: 'https://sap.mw.edificiosano.com/selling-services/{{sapOrder}}',
            }
        },
        apiKeys: {
            qap: 'thisshouldbe.arandomgenerated.key',
            prod: 'thisshouldbe.arandomgenerated.key',
        },
        apiHeaders: {
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
            'Allow': 'GET, POST, OPTIONS, PUT, DELETE',
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'Accept': 'application/json, text/plain'
        },
        format: 'json'
    },
    endpoints: {
        // Users and accounts
        roles: 'roles',
        permissions: 'permissions',
        users: 'users',
        
        // Tracking
        sellingServices: 'selling_services',
        serviceTypes: 'service_types',
        checkpoints: 'checkpoints',
        projects: 'projects',
        technicians: 'technicians',
        quadrilles: 'quadrilles',

        //file management
        files: 'files',

        //Polls
        polls: 'polls',

        //ImageGallery
        SellingServiceImages: 'selling_service_images',
        attendances: 'technician_attendances',

        //DrivingEvents
        drivingEvents: 'driving_events',

        //Post-sell schedules services
        postSellServices: 'post_sell_schedules',

        //Service Presences (attendances 2.0)
        presences: 'presences',

        //Bonifications
        cutoffRanges: 'cutoff_ranges',
        bonificationsSettings: 'bonifications_settings',
        bonifications: 'bonifications',

        observerTasks: 'observer_tasks',
        meetings: 'meetings',
        meetingAttendances: 'meeting_attendances',

        sellingServicesComments: 'selling_services_comments',
        
        //Cronogram
        planifications: 'planifications',

        //Control tasks
        controlTasks: 'control_tasks',

        //Market Quotes
        marketQuotesCalculator: 'market_quotes_calculator'
    }
};