import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-custom-progress-bar',
  templateUrl: './custom-progress-bar.component.html',
  styleUrls: ['./custom-progress-bar.component.css']
})
export class CustomProgressBarComponent implements OnInit, OnChanges {

  @Input() progress: number;
  
  styleClass: any;
  customNgClass: string[];

  constructor() { 
    this.styleClass = 0;
  }
  ngOnChanges(): void {
    this.buildNgClass();
    this.buildStyle();
  }

  ngOnInit(): void {
    this.buildNgClass();
    this.buildStyle();
  }

  buildStyle(){
    this.styleClass = {
      'width': `${ this.progress }%`
    }
  }

  buildNgClass(){
    this.customNgClass = [
      'progress-bar',
    ];

    if(this.progress <= 33 ){
      this.customNgClass.push('bg-danger')
      return;
    }
    if(this.progress <= 66 ){
      this.customNgClass.push('bg-warning');
      return;
    }
    if(this.progress <= 99 ){
      this.customNgClass.push('bg-info');
      return;
    }
    this.customNgClass.push('bg-success');
  }

}
