import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'file-manager-multi-file',
  templateUrl: './multi-file.component.html',
  styleUrls: ['./multi-file.component.css']
})
export class MultiFileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
