import { Component, OnInit } from '@angular/core';
import { SessionHandlerService } from '../../services/session-handler.service';
import { RouterHelperService } from '../../services/router-helper.service';
import { NotificationService } from '../../services/notification.service';
import { ENUM_PERMISSIONS } from '../../enums/permissions';
import { appConfig } from 'src/app/config/app-config';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  sessionHandlerServiceRef: SessionHandlerService;  
  public permissions: typeof ENUM_PERMISSIONS = ENUM_PERMISSIONS;
  constructor(
    private sessionHandlerService: SessionHandlerService,
    private routerHelperService: RouterHelperService,
    private notificationService: NotificationService,
  ) { 
    this.sessionHandlerServiceRef = sessionHandlerService;
  }

  ngOnInit(): void {
  }

  signOut(): void{
    this.sessionHandlerService.clearStorage();
    this.routerHelperService.redirectToLogin();
    this.notificationService.showInfo('Su sesión ha sido cerrada exitosamente')
  }

  userLoggedIn() : boolean {
    return !!this.sessionHandlerService.getUserAuthenticated();
  }

  isProdEnvironment(){
    return appConfig.environment === 'prod';
  }
}
