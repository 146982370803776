import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleFileComponent } from './components/single-file/single-file.component';
import { MultiFileComponent } from './components/multi-file/multi-file.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [SingleFileComponent, MultiFileComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    SingleFileComponent,
    MultiFileComponent
  ]
})
export class FileManagerModule { }
