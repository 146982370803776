import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ENUM_PERMISSIONS } from 'src/app/common/enums/permissions';
import { SessionHandlerService } from 'src/app/common/services/session-handler.service';

@Injectable({
  providedIn: 'root'
})
export class PostSellSchedulesModuleAccessGuard implements CanActivate {
  public permissions: typeof ENUM_PERMISSIONS = ENUM_PERMISSIONS;
  constructor(
    private sessionHandlerService: SessionHandlerService
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.sessionHandlerService.permissionExists(this.permissions.POSTVENTA_ACCESO_MODULO);
  }
  
}
