import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { APILinkerService } from './apilinker.service';
import { SessionHandlerService } from './session-handler.service';
import { StorageService } from './storage.service';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
  ],
  providers:[
    APILinkerService,
    SessionHandlerService,
    StorageService
  ]
})
export class AppServicesModule { }
