import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { WeekDatePickerModel } from '../../models/week-date-picker/week-date-picker.model';


@Component({
  selector: 'week-date-picker',
  templateUrl: './week-date-picker.component.html',
  styleUrls: ['./week-date-picker.component.css']
})
export class WeekDatePickerComponent implements OnInit {

  @Input() weekDatePicker: WeekDatePickerModel;
  @Output() weekDatePickerChange = new EventEmitter();
  @Output() onChange = new EventEmitter();
 
  constructor() { 
    
  }

  ngOnInit(): void {

  }

  emitOnRequest(){
    this.weekDatePickerChange.emit(this.weekDatePicker);
  }

  emitOnChange(){
    this.emitOnRequest();
    this.onChange.emit(this.weekDatePicker);
  }

}
