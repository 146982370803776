<ngx-loading-bar color="#93BB1D" [includeSpinner]="true" [height]="2"></ngx-loading-bar>

<app-navbar></app-navbar>

<div class="container">
    <router-outlet></router-outlet>
</div>

<!--
<div class="container">
    <file-manager-single-file></file-manager-single-file>
</div>
-->